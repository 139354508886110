<template>
  <a-card class="step-card">
    <a-row :gutter="8">
      <a-col :span="12">
        <a-form-item
          label="Negative Step"
          :labelCol="{ span: 24 }"
          :wrapperCol="{ span: 24 }"
          class="custom-form-item"
        >
          <a-select
            :value="stepData.key1"
            @change="updateKey1"
            :disabled="stepData.update"
            :placeholder="
              availableOptions?.length
                ? 'Select an option'
                : 'No options available'
            "
          >
            <a-select-option
              v-for="option in filteredOptions1"
              :key="option"
              :value="option"
            >
              {{ option }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>
      <a-col :span="12">
        <a-form-item
          label="Rectification Step"
          :labelCol="{ span: 24 }"
          :wrapperCol="{ span: 24 }"
          class="custom-form-item"
        >
          <a-select
            :value="stepData.key2"
            @change="updateKey2"
            :disabled="isKey2Disabled || stepData.update"
            :placeholder="
              availableOptions?.length
                ? 'Select an option'
                : 'No options available'
            "
          >
            <a-select-option
              v-for="option in filteredOptions2"
              :key="option"
              :value="option"
            >
              {{ option }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-col>
      <a-col :span="24">
        <a-button
          type="danger"
          @click="deleteStep(stepData.id)"
          class="delete-button"
        >
          Delete Step
        </a-button>
      </a-col>
    </a-row>
  </a-card>
</template>

<script>
export default {
  name: 'NegativeStepCard',
  props: {
    onDelete: {
      type: Function,
      required: true,
    },
    stepData: {
      type: Object,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    negativeOptions: {
      type: Array,
      required: true,
    },
  },
  computed: {
    filteredOptions1() {
      return this.negativeOptions.filter(
        (option) => option !== this.stepData.key2
      );
    },
    filteredOptions2() {
      return this.options.filter((option) => option !== this.stepData.key1);
    },
    isKey2Disabled() {
      return !this.stepData.key1;
    },
  },
  methods: {
    updateKey1(value) {
      this.$emit('update-step', { ...this.stepData, key1: value });
    },
    updateKey2(value) {
      this.$emit('update-step', { ...this.stepData, key2: value });
    },
    deleteStep(id) {
      this.onDelete(id);
    },
  },
};
</script>

<style scoped>
.step-card {
  background-color: #f9f9f9; /* Light gray background */
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 16px; /* Padding around the card */
  margin-bottom: 16px; /* Spacing between cards */
}

.custom-form-item {
  margin-bottom: 16px; /* More space between form items */
}

.delete-button {
  width: 100%; /* Full width for the delete button */
  margin-top: 10px; /* Space above the button */
  background-color: #ff4d4f; /* A more vibrant red */
  border-color: #ff4d4f; /* Same color for the border */
}

.delete-button:hover {
  background-color: #ff7875; /* Lighter shade on hover */
  border-color: #ff7875; /* Same for border */
}
</style>
