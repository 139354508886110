<template>
  <div class="py-1 px-4">
    <AddTag :device="device" @refresh="refreshTags" />
  </div>
  <div class="py-4 px-2 p-md-4">
    <div class="opc-tag-table">
      <a-table
        class="table-container"
        :columns="columns"
        :data-source="tags"
        :pagination="false"
        :loading="isTagsLoading"
        :scroll="{ x: 'max-content', y: 410 }"
      >
        <template #bodyCell="{ record, column, index }">
          <!-- TagId Column -->
          <div v-if="column.dataIndex === 'tag_id'" class="tagId-container">
            <a-input
              v-if="previousData[record.id]"
              v-model:value="record.tag_name"
              style="margin: -5px 0; width: 250px"
              :disabled="isTagUpdating[record.id]"
            />
            <span v-else>
              <a-tooltip :title="record.tag_id">
                <div class="ellipsis-text">
                  {{ record.tag_name }}
                </div></a-tooltip
              >
            </span>
          </div>

          <!-- Editable Column -->
          <div class="text-center" v-if="column.dataIndex === 'editable'">
            <span>
              <a-tag v-if="record.editable === false" color="blue">read</a-tag>
              <a-tag v-else color="green">write</a-tag>
            </span>
          </div>
          <!-- Action Column -->
          <div v-if="column.dataIndex === 'action'">
            <span class="d-flex ml-auto mt-4">
              <a-form-item name="select">
                <a-select
                  v-model:value="record.trigger_on"
                  placeholder="Please select a trigger"
                  allowClear="true"
                  style="width: 180px"
                  :options="triggers"
                  @change="handleTriggerChange(record)"
                />
              </a-form-item>
              <a-button
                type="danger"
                class="ml-4"
                @click="deleteTag(record.id)"
              >
                <template #icon>
                  <delete-outlined />
                </template>
              </a-button>
            </span>
          </div>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import { DeleteOutlined } from '@ant-design/icons-vue';
import AddTag from './AddTag.vue';
import IoTService from 'src/services/iot';

export default {
  components: {
    AddTag,
    DeleteOutlined,
  },
  inject: ['toast'],
  props: ['selectedTask', 'device'],

  data() {
    return {
      columns: [
        {
          title: 'Tag ID',
          dataIndex: 'tag_id',
          key: 'tag_id',
          width: '25%',
        },
        {
          title: 'Data Type',
          dataIndex: 'data_type',
          key: 'data_type',
          width: '20%',
        },
        {
          title: 'Access Level',
          dataIndex: 'editable',
          key: 'editable',
          width: '20%',
        },
        {
          title: 'Action',
          dataIndex: 'action',
          key: 'action',
          width: '35%',
        },
      ],
      triggers: [
        {
          value: 'cycle_start',
          label: 'Cycle Start',
        },
        {
          value: 'cycle_end',
          label: 'Cycle End',
        },
        {
          value: 'missed_step',
          label: 'Missed Step',
        },
        {
          value: 'step_completion',
          label: 'Step Completion',
        },
        {
          value: 'verify_step',
          label: 'Verify Step',
        },
        {
          value: 'mark_step_completion',
          label: 'Mark Step Completion',
        },
      ],
      tags: [],
      previousTrigger: [],
      addTagModalVisible: false,
      updateModalVisible: false,
      isTagsLoading: false,
      showDeletePopup: false,
      toastTimeoutDuration: 3000,
      errorMessage: 'An unexpected error has occured. Please refresh the page.',
    };
  },
  created() {
    this.getTagList();
  },
  methods: {
    updatePreviousTrigger() {
      this.previousTrigger = this.tags.map((tag) => ({
        id: tag.id,
        trigger_on: tag.trigger_on,
      }));
    },
    refreshTags(data) {
      this.tags.push(data);
      this.updatePreviousTrigger();
    },
    isDeviceOrTaskEmpty() {
      return (
        this.selectedTask === null ||
        this.selectedTask === undefined ||
        this.device?.Device === null ||
        this.device?.Device === undefined
      );
    },
    async getTagList() {
      try {
        if (this.isDeviceOrTaskEmpty()) {
          this.isTagsLoading = false;
          return this.toast.error('Unable to fetch OPC Tags.', {
            timeout: this.toast.toastTimeoutDuration,
          });
        }
        const deviceId =
          typeof this.device.Device === 'object'
            ? this.device.Device.id
            : this.device.Device;
        this.isTagsLoading = true;
        const [error, data] = await IoTService.getOPCTagsByTaskAndDevice(
          this.selectedTask,
          deviceId
        );
        if (error) {
          this.isTagsLoading = false;
          return this.toast.error('Failed to reterive OPC Tags.', {
            timeout: this.toastTimeoutDuration,
          });
        }
        const org = localStorage.getItem('organization');
        this.isTagsLoading = false;
        this.tags = data.filter((tag) => {
          return tag.org_id === org;
        });
        this.updatePreviousTrigger();
      } catch (error) {
        this.isTagsLoading = false;
        return this.toast.error(this.errorMessage);
      }
    },
    async deleteTag(tagId) {
      try {
        this.isTagsLoading = true;
        const [error, data] = await IoTService.deleteOPCTag(tagId);
        if (error) {
          this.isTagsLoading = false;
          return this.toast.error('Failed to delete tag. Please Try again!', {
            timeout: this.toastTimeoutDuration,
          });
        }
        this.tags = this.tags.filter((tag) => tag.id !== tagId);
        this.updatePreviousTrigger();
        this.isTagsLoading = false;
        this.toast.success('OPC Tag deleted successfully.');
      } catch (error) {
        this.isTagsLoading = false;
        return this.toast.error(this.errorMessage);
      }
    },
    async handleTriggerChange(record) {
      try {
        if (record.id === undefined || record.id === null || record.id === '') {
          return this.toast.error(
            'OPC tag is empty. Unable to update the OPC Trigger.',
            {
              timeout: this.toastTimeoutDuration,
            }
          );
        }
        this.isTagsLoading = true;
        if (record?.trigger_on === undefined || record?.trigger_on === '') {
          record.trigger_on = null;
        }
        const [error, _] = await IoTService.updateTag(record.id, record);
        if (error) {
          this.isTagsLoading = false;
          this.updateTriggerToPreviousState(record);
          return this.toast.error('Failed to update OPC Trigger.', {
            timeout: this.toastTimeoutDuration,
          });
        }
        this.isTagsLoading = false;
        this.toast.success('OPC Trigger updated successfully.');
        this.updatePreviousTrigger();
      } catch (error) {
        this.isTagsLoading = false;
        this.updateTriggerToPreviousState(record);
        return this.toast.error(this.errorMessage);
      }
    },
    updateTriggerToPreviousState(record) {
      const matchingTrigger = this.previousTrigger.find(
        (trigger) => trigger.id === record.id
      );
      record.trigger_on = matchingTrigger.trigger_on || null;
    },
  },
};
</script>

<style>
/* Add your styles here */
.ellipsis-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.tagId-container {
  width: 85%;
}
.add-tag {
  float: right;
}
.tag-button-container {
  height: 0.5rem;
  margin-top: 2%;
  width: 100%;
}
</style>
