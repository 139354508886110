<template>
  <div class="h-100 d-flex flex-column">
    <a-card
      v-for="(process, processIndex) in taskProcesses"
      :key="process.name"
      class="process-container"
      hoverable
      :title="process.name"
      :bordered="false"
      :body-style="{
        flexGrow: '1',
        height: '1px',
        overflowY: 'auto',
        overflowX: 'hidden',
      }"
    >
      <div style="text-align: right">
        <a-button
          style="background-color: #f10000; border-color: #f10000"
          type="primary"
          size="small"
          :loading="loadingCustomCode"
          @click="openPopup('__init__')"
        >
          Code
        </a-button>
      </div>

      <!-- <div style=" display: flex; justify-content:end;">
        <a-button type="ghost" @click="addVerifiedSteps">
          <template #icon><CheckOutlined style="color:#5DC877"/></template>
          Verify Step
        </a-button>
      </div> -->
      <div v-for="(step, stepIndex) in steps(processIndex)" :key="step.name">
        <h6 class="mb-3">
          {{ step.name }}
        </h6>

        <template
          v-for="(substep, substepIndex) in substeps(processIndex, stepIndex)"
          :key="substep"
        >
          <!-- Bool Express -->
          <!-- Selection bar -->
          <div style="display: flex; justify-content: space-between">
            <a-space>
              <h6 style="font-weight: bold; margin: 0">
                {{ substep }}
              </h6>
              <a-checkbox
                v-model:checked="verifiedSteps[stepIndex][substepIndex]"
                >verify
              </a-checkbox>
            </a-space>
            <a-space>
              <a-button
                style="background-color: #9accf2; border-color: #9accf2"
                type="primary"
                size="small"
                @click="
                  addExpression('object', process.name, step.name, substep)
                "
              >
                Object
              </a-button>
              <a-button
                style="background-color: #5dc877; border-color: #5dc877"
                type="primary"
                size="small"
                @click="
                  addExpression('region', process.name, step.name, substep)
                "
              >
                Region
              </a-button>
              <a-button
                style="background-color: #f1ae00; border-color: #f1ae00"
                type="primary"
                size="small"
                @click="
                  addExpression('operator', process.name, step.name, substep)
                "
              >
                Operator
              </a-button>
              <a-button
                style="background-color: #ce15e7; border-color: #ce15e7"
                type="primary"
                size="small"
                @click="addExpression('time', process.name, step.name, substep)"
              >
                Time
              </a-button>
              <div>
                <a-button
                  style="background-color: #f10000; border-color: #f10000"
                  type="primary"
                  size="small"
                  :loading="loadingCustomCode"
                  @click="openPopup(substep)"
                >
                  Code
                </a-button>
              </div>
            </a-space>
          </div>

          <!-- Expression -->

          <div class="py-2" style="width: 85vw">
            <template
              v-for="(exp, index) in expressions[process.name][step.name][
                substep
              ]"
              :key="exp.type + index"
              style="display: flex; flex-direction: row; width: 90%"
            >
              <a-tooltip>
                <template #title>{{ exp.value }}</template>
                <a-select
                  v-if="exp.type == 'region'"
                  ref="select"
                  v-model:value="exp.value"
                  class="m-1"
                  style="
                    width: 220px;
                    border: 0.2px solid #5dc877;
                    color: #5dc877;
                  "
                  :disabled="exp.edit == 0"
                  @focus="focus"
                >
                  <a-select-option
                    v-for="option in staticObjectOptions"
                    :value="option.value"
                    >{{ option.label }}
                    <sup v-if="option.is_3d"> 3D</sup></a-select-option
                  >

                  <template
                    v-if="
                      index ==
                      expressions[process.name][step.name][substep].length - 1
                    "
                    #suffixIcon
                  >
                    <CloseOutlined
                      style="color: red"
                      @click="
                        removeOption(index, process.name, step.name, substep)
                      "
                    />
                  </template>
                </a-select>
              </a-tooltip>

              <a-tooltip>
                <template #title>{{ exp.value }}</template>
                <a-select
                  v-if="exp.type == 'object'"
                  ref="select"
                  v-model:value="exp.value"
                  class="m-1"
                  style="
                    width: 220px;
                    border: 0.2px solid #9accf2;
                    color: #9accf2;
                  "
                  :disabled="exp.edit == 0"
                  @focus="focus"
                >
                  <a-select-option
                    v-for="option in nonStaticObjectOptions"
                    :value="option.value"
                    >{{ option.label }}
                    <sup v-if="option.is_3d"> 3D</sup>
                  </a-select-option>
                  <template
                    v-if="
                      index ==
                      expressions[process.name][step.name][substep].length - 1
                    "
                    #suffixIcon
                  >
                    <CloseOutlined
                      style="color: red"
                      @click="
                        removeOption(index, process.name, step.name, substep)
                      "
                    />
                  </template>
                </a-select>
              </a-tooltip>

              <a-select
                v-if="exp.type == 'operator'"
                ref="select"
                v-model:value="exp.value"
                class="m-2"
                :disabled="exp.edit == 0"
                style="
                  border: 0.2px solid #f1ae00;
                  color: #f1ae00;
                  background-color: #f1ae00;
                "
                @focus="focus"
              >
                <a-select-option value="AND"> AND </a-select-option>
                <a-select-option value="OR"> OR </a-select-option>
                <a-select-option value="NOT"> NOT </a-select-option>
                <template
                  v-if="
                    index ==
                    expressions[process.name][step.name][substep].length - 1
                  "
                  #suffixIcon
                >
                  <CloseOutlined
                    style="color: red"
                    @click="
                      removeOption(index, process.name, step.name, substep)
                    "
                  />
                </template>
              </a-select>
              <a-input
                v-if="exp.type == 'time'"
                v-model:value="exp.value"
                :disabled="exp.edit == 0"
                type="number"
                placeholder="Action Time"
                style="border: 0.2px solid #ce15e7; padding: 5px; width: 100px"
                @focus="focus"
              >
                <template #prefix> FOR </template>
                <template
                  v-if="
                    index ==
                    expressions[process.name][step.name][substep].length - 1
                  "
                  #suffix
                >
                  <CloseOutlined
                    style="color: red"
                    @click="
                      removeOption(index, process.name, step.name, substep)
                    "
                  />
                </template>
              </a-input>
            </template>
          </div>
        </template>

        <a-divider />
      </div>
      <template #actions>
        <a-button
          type="primary"
          class="float-right mr-5"
          :loading="loading"
          :disabled="isDisable"
          @click="generateAssociateObjectJson"
        >
          Save
        </a-button>
      </template>
    </a-card>
  </div>
  <a-modal
    :visible="openCodeEditor"
    centered
    destroy-on-close
    :closable="false"
    :footer="null"
    :title="Translation"
    width="75vw"
    @cancel="closePopup"
  >
    <Editor
      :close="closePopup"
      v-bind:stepname="this.currentStep"
      :codevalue="this.customCodeByTask[this.currentStep]"
      @saveCode="saveCode"
    ></Editor>
  </a-modal>
</template>

<script>
import { ref } from 'vue';
import { mapGetters, mapActions } from 'vuex';
import StepObjects from './StepObjects.vue';
import { objectAnnotationModes } from 'src/config/task-steps-objects.js';
import {
  PlusOutlined,
  CloseOutlined,
  CheckOutlined,
} from '@ant-design/icons-vue';
import Editor from './CodeEditor.vue';
import S3Service from 'src/services/s3';
import axios from 'axios';

export default {
  components: {
    StepObjects,
    PlusOutlined,
    CloseOutlined,
    CheckOutlined,
    Editor,
  },
  inject: ['toast'],
  props: ['pipelineStep'],
  emits: ['nextStep'],

  setup() {
    return { objectAnnotationModes };
  },
  data() {
    return {
      expressions: {},
      loading: false,
      loadingCustomCode: false,
      verifiedSteps: [],
      is_verified: false,
      openCodeEditor: false,
      currentStep: '',
      customCode: '',
      customCodeByTask: {},
      stepname: [],
    };
  },

  computed: {
    ...mapGetters([
      'taskProcesses',
      'steps',
      'substeps',
      'stepsToIndexMapping',
      'annotationObjectJson',
      'stepsJsonData',
      'objectWarnings',
      'taskObjects',
      'organization',
      'selectedTask',
      'taskName',
    ]),

    staticObjectOptions() {
      return this.taskObjects
        .filter(({ is_static }) => is_static === true)
        .map(({ id, name, is_3d }) => ({
          value: name,
          label: name,
          key: id,
          is_3d: is_3d,
        }));
    },

    nonStaticObjectOptions() {
      return this.taskObjects
        .filter(({ is_static }) => is_static !== true)
        .map(({ id, name, is_3d }) => ({
          value: name,
          label: name,
          key: id,
          is_3d: is_3d,
        }));
    },
    isDisable() {
      const steps = Object.keys(this.objectWarnings);
      if (!steps.length) return false;
      return steps.some((stepIndex) => this.objectWarnings[stepIndex] === true);
    },
  },

  async beforeMount() {
    this.changeAnnotationObjectTab(objectAnnotationModes['annotation']);
    this.setAnnotationObjectJson();
    this.customCodeByTask['__init__'] = '';
    this.taskProcesses.forEach((process) => {
      this.expressions[process.name] = {};
      process['steps'].forEach((step) => {
        this.expressions[process.name][step.name] = {};
        this.verifiedSteps.push([]);
        step['substeps'].forEach((substep) => {
          this.expressions[process.name][step.name][substep] = [];
          this.customCodeByTask[substep] = '';
          this.stepname.push(substep);
        });
      });
    });
    let key = Object.keys(this.stepsJsonData)[0];
    if (
      key != null &&
      this.stepsJsonData[key].hasOwnProperty('rule_type') &&
      this.stepsJsonData[key]['rule_type']
    ) {
      this.generateOldExpressionfromStepData();
    } else {
      this.generateExpressionfromStepData();
    }
    await this.readCodeFromFile();
    this.parseCustomCodeTaskVise();
    console.log(this.customCodeByTask['trigger start cycle']);
  },

  methods: {
    ...mapActions([
      'setAnnotationObjectJson',
      'changeAnnotationObjectTab',
      'setStepsJsonData',
      'createStepsJsonData',
    ]),
    parseCustomCodeTaskVise() {
      let substepNames = ['__init__'];
      this.taskProcesses.forEach((process) => {
        process['steps'].forEach((step) => {
          step['substeps'].forEach((substep) => {
            substepNames.push(substep);
          });
        });
      });
      var lines = this.customCode.split('\n');
      console.log('lines->', lines);
      let startParsing = '';
      let currentStep = '';
      let flag = -1;
      for (let i = 0; i < lines.length; i++) {
        if (lines[i].includes('# Custom') && flag == -1) {
          flag = 1;
          if (startParsing == '') startParsing += lines[i];
          else startParsing = startParsing + '\n' + lines[i];
          substepNames.forEach((substep) => {
            if (lines[i].includes(substep)) {
              currentStep = substep;
            }
          });
        } else if (
          (lines[i].includes('# Custom') || i == lines.length - 1) &&
          flag == 1
        ) {
          if (i == lines.length - 1)
            startParsing = startParsing + '\n' + lines[i];
          this.customCodeByTask[currentStep] = startParsing;
          flag = -1;
          startParsing = '';
          currentStep = '';
          i -= 1;
          continue;
        } else {
          if (startParsing == '') startParsing += lines[i];
          else startParsing = startParsing + '\n' + lines[i];
        }
      }
    },
    async readCodeFromFile() {
      this.loadingCustomCode = true;
      const bucket = `${this.organization}-training`;
      const filePath = `${this.taskName}/SwappableObjectDetector/custom_code.py`;

      const [error, data] = await S3Service.readFile({
        bucket: bucket,
        file_path: filePath,
      });
      if (data.error == 'NoSuchKey' || data.length < 2) {
        this.loadingCustomCode = false;
        let data =
          `# Custom function for __init__
def __init__(self,detection):
  # write your code here!
  self.updated_time = "${new Date().toISOString()}"
  self.detection = detection
` + '\n';
        this.stepname.forEach((step) => {
          let functionName = '';
          step.split(' ').forEach((value) => {
            if (functionName.length == 0) functionName = value;
            else functionName = functionName + '_' + value;
          });
          functionName = functionName
            .replace(/[^a-zA-Z0-9-]/g, '_')
            .replace(/-/g, '_');

          data +=
            `# Custom function for ${step}
def detector_${functionName}(self,current_detection,classification_point):
  # write your code here!
  return False` + '\n';
        });
        data +=
          `# Custom function for get_predictions
def get_predictions(self, current_detection,classification_point):
  # Get all methods in the class
  preds = []
  methods = [func for func in CustomCode.__dict__ if callable(getattr(self, func)) and not func.startswith("__")]

  # Call all functions one by one
  for index,method in enumerate(methods):
    if method != "get_predictions":
      func = getattr(self, method)
      result = func(current_detection, classification_point)
      if result is True:
        preds.append(index)

  return preds` + `\n`;
        this.customCode = data;
        return;
      }
      let lines = data.split('\n');
      for (let i = 1; i < lines.length; i++) {
        this.customCode += lines[i].slice(2) + '\n';
      }

      this.loadingCustomCode = false;
    },
    getPayload() {
      const bucket = `${this.organization}-training`;
      const filePath = `${this.taskName}/SwappableObjectDetector/`;
      const payload = new FormData();

      payload.append('bucket', bucket);
      payload.append('file_path', filePath);
      payload.append('file');
      return payload;
    },
    async getPresignUrl() {
      let payload = {
        bucket: `${this.organization}-training`,
        key: `${this.taskName}/SwappableObjectDetector/custom_code.py`,
      };
      const [error, data] = await S3Service.getPresignedPostURL(payload, false);
      return error ? error : data;
    },

    async createAWSFormData(data) {
      let awsformData = new FormData();
      Object.keys(data.fields).forEach((key) => {
        awsformData.append(key, data.fields[key]);
      });
      let customCodeModule = 'class CustomCode: \n';
      let code = this.customCode.split('\n');
      for (let i = 0; i < code.length; i++) {
        if (code[i].includes('self.updated_time')) {
          code[i] = `  self.updated_time = "${new Date().toISOString()}"`;
        }
      }
      this.customCode = code.join('\n');
      this.customCode.split('\n').forEach((line) => {
        customCodeModule += '  ' + line + '\n';
      });
      console.log('value ->', customCodeModule);
      const blob = new Blob([customCodeModule], { type: 'text/python' });
      const file = new File([blob], 'custom_code.py', { type: 'text/python' });

      awsformData.append('file', file);
      return awsformData;
    },
    async saveFileViaPresignedURL() {
      const data = await this.getPresignUrl();
      console.log(data);
      if (!data) {
        this.toast.error('Could not access storage');
        return;
      }
      let awsformData = await this.createAWSFormData(data);

      // let awsformData = this.createAWSFormData(
      //   new File([this.code], 'custom_code.py', {
      //     type: 'text/python'
      //   }),
      //   data
      // );
      await this.uploadFileToS3(data.url, awsformData);
    },
    async uploadFileToS3(url, data) {
      const headers = {
        'Content-Type': 'multipart/form-data',
        Accept: '*/*',
      };
      await axios.post(url, data, {
        headers,
      });
    },
    async upload() {
      await this.saveFileViaPresignedURL();
    },
    async saveCode(code, stepname) {
      this.customCode = '';
      this.customCodeByTask[stepname] = code;
      for (const [key, value] of Object.entries(this.customCodeByTask)) {
        this.customCode += value + '\n';
      }

      this.parseCustomCodeTaskVise();
    },
    openPopup(substep) {
      if (substep == null) {
        this.customCode = this.openCodeEditor = true;
      } else {
        this.currentStep = substep;
        this.openCodeEditor = true;
      }
    },
    closePopup() {
      this.openCodeEditor = false;
    },
    validateExpressionOnSave() {
      let valid = true;

      this.taskProcesses.forEach((process) => {
        process['steps'].forEach((step) => {
          step['substeps'].forEach((substep) => {
            var exp = this.expressions[process.name][step.name][substep];
            if (exp.length != 0 && exp[exp.length - 1].type == 'operator') {
              this.toast.error(
                `Invalid Step (${step.name}) : ` +
                  'Operator cannot be last item.'
              );
              valid = false;
            }

            // only AND NOT and OR NOT allowed
            for (let i = 0; i < exp.length; i++) {
              if (i + 1 == exp.length) break;
              if (
                exp[i + 1].type == 'operator' &&
                exp[i + 1].type == exp[i].type &&
                exp[i + 1].value != 'NOT'
              ) {
                valid = false;
                this.toast.error(
                  `Invalid Step (${step.name}) : ` +
                    "Cannot use 'AND' and 'OR' operators consecutively."
                );
                break;
              }
            }

            // invalid not operator

            for (let i = 0; i < exp.length; i++) {
              if (exp[i].type == 'operator' && exp[i].value == 'NOT') {
                if (i != 0 && exp[i - 1].type != 'operator') {
                  valid = false;
                  this.toast.error(
                    `Invalid Step (${step.name}) : ` +
                      "Incorrect usage of 'NOT' operator."
                  );
                  break;
                } else if (i != 0 && exp[i - 1].value == 'NOT') {
                  valid = false;
                  this.toast.error(
                    `Invalid Step (${step.name}) : ` +
                      "Incorrect usage of 'NOT' operator."
                  );
                  break;
                }
              }
            }

            // invalid time operator
            if (exp.length > 0 && exp[0].type == 'time') {
              valid = false;
              this.toast.error(
                `Invalid Step (${step.name}) : ` +
                  "Incorrect usage of 'Time' operator."
              );
            }
            for (let i = 0; i < exp.length; i++) {
              if (exp[i].type == 'time') {
                if (i - 1 >= 0 && exp[i - 1].type == 'operator') {
                  valid = false;
                  this.toast.error(
                    `Invalid Step (${step.name}) : ` +
                      "Incorrect usage of 'Time' operator."
                  );
                  break;
                }
                if (i + 1 != exp.length && exp[i + 1].type != 'operator') {
                  valid = false;
                  this.toast.error(
                    `Invalid Step (${step.name}) : ` +
                      "Incorrect usage of 'Time' operator."
                  );
                  break;
                }
              }
            }

            // region should have an object
            for (let i = 0; i < exp.length; i++) {
              if (exp[i].type === 'region') {
                if (
                  (i === 0 || exp[i - 1].type !== 'object') &&
                  (i === exp.length - 1 || exp[i + 1].type !== 'object')
                ) {
                  valid = false;
                  this.toast.error(
                    `Invalid Step (${step.name}) : ` +
                      'Please choose an object for the step.'
                  );
                  break;
                }
              }
            }
          });
        });
      });
      return valid;
    },
    validation(expression, newExp) {
      if (
        expression.length != 0 &&
        expression[expression.length - 1].type == 'region' &&
        newExp == 'region'
      ) {
        this.toast.error('Interaction not possible.');

        return false;
      }
      if (
        expression.length - 3 >= 0 &&
        expression[expression.length - 1].type != 'operator' &&
        expression[expression.length - 2].type != 'operator' &&
        expression[expression.length - 3].type != 'operator' &&
        newExp != 'operator'
      ) {
        this.toast.error('Interaction not possible.');
        return false;
      }

      return true;
    },
    addExpression(newExp, processName, stepName, subStepName) {
      // validation
      let expression = this.expressions[processName][stepName][subStepName];

      if (!this.validation(expression, newExp)) {
        return;
      }
      if (newExp == 'object') {
        this.expressions[processName][stepName][subStepName].push({
          type: newExp,
          value: this.nonStaticObjectOptions[0].value,
          edit: 1,
        });
      } else if (newExp == 'region') {
        this.expressions[processName][stepName][subStepName].push({
          type: newExp,
          value: this.staticObjectOptions[0].value,
          edit: 1,
        });
      } else if (newExp == 'time') {
        this.expressions[processName][stepName][subStepName].push({
          type: newExp,
          value: 5,
          edit: 1,
        });
      } else {
        for (
          let i = 0;
          i < this.expressions[processName][stepName][subStepName].length;
          i++
        ) {
          this.expressions[processName][stepName][subStepName][i]['edit'] = 0;
        }
        this.expressions[processName][stepName][subStepName].push({
          type: newExp,
          value: 'AND',
          edit: 1,
        });
      }
      console.log(this.expressions);
    },
    removeOption(index, processName, stepName, subStepName) {
      this.expressions[processName][stepName][subStepName].splice(index, 1);
      this.expressions[processName][stepName][subStepName][
        this.expressions[processName][stepName][subStepName].length - 1
      ].edit = 1;
    },
    async generateAssociateObjectJson() {
      this.loading = true;
      if (!this.validateExpressionOnSave()) {
        this.loading = false;
        return;
      }

      console.log('expressions', this.expressions);
      let stepdata = this.generateStepsData();

      this.setStepsJsonData(stepdata);
      console.log(this.customCode);
      await this.upload();
      await this.createStepsJsonData(stepdata);

      this.toast.success('Association Saved');
      this.loading = false;
    },

    validateAnnotationObjects() {
      const steps_data = this.annotationObjectJson['annotation'];
      return Object.values(steps_data).some(
        ({ static_object, non_static_object }) =>
          static_object && !non_static_object.length
      );
    },

    generateOldExpressionfromStepData() {
      let stepdata = this.stepsJsonData;
      let stepExpression = [];
      for (var key of Object.keys(stepdata)) {
        let rule_type = stepdata[key]['rule_type'];
        if (!rule_type) return;
        let detectionType = Object.keys(rule_type)[0];
        let objects = rule_type[detectionType]['objects'];
        let region = rule_type[detectionType]['region'];
        let expressionFound = [];

        if (objects.length > 1) {
          // 2 objects
          expressionFound.push({
            type: 'object',
            value: objects[0],
            edit: 1,
          });
          expressionFound.push({
            type: 'object',
            value: objects[1],
            edit: 1,
          });
        } else if (
          (region == null || region.length == 0) &&
          objects.length == 1
        ) {
          // 1 object
          expressionFound.push({
            type: 'object',
            value: objects[0],
            edit: 1,
          });
        } else {
          // object region
          expressionFound.push({
            type: 'object',
            value: objects[0],
            edit: 1,
          });
          expressionFound.push({
            type: 'region',
            value: region,
            edit: 1,
          });
        }
        stepExpression.push(expressionFound);
        let stepIndex = 0;
        this.taskProcesses.forEach((process) => {
          process['steps'].forEach((step) => {
            step['substeps'].forEach((substep) => {
              this.expressions[process.name][step.name][substep] =
                stepExpression[stepIndex];
              stepIndex += 1;
            });
          });
        });
      }
    },
    generateExpressionfromStepData() {
      let stepdata = this.stepsJsonData;
      let stepExpression = [];
      let verifiedStepDate = [];
      for (var key of Object.keys(stepdata)) {
        let boolean_expr = stepdata[key]['boolean_expr'];
        verifiedStepDate.push(!stepdata[key]['detect_always']);
        if (boolean_expr.length == 0 || boolean_expr == null) {
          stepExpression.push([]);
          continue;
        }
        let rule_types = stepdata[key]['rule_types'];
        let values = boolean_expr.split(' ');
        console.log('val', values);

        let expressionFound = [];
        values.forEach((value) => {
          if (value.includes('FOR_')) {
            console.log('values', value);
            let timeFormat = value.split('_');
            expressionFound.push({
              type: 'time',
              value: timeFormat[timeFormat.length - 1],
              edit: 1,
            });
          } else if (['AND', 'OR', 'NOT', 'FOR_'].includes(value)) {
            if (value.includes('FOR_')) {
              console.log('values', value);
              let timeFormat = value.split('_');
              expressionFound.push({
                type: 'time',
                value: timeFormat[timeFormat.length - 1],
                edit: 1,
              });
            }
            expressionFound.push({
              type: 'operator',
              value: value,
              edit: 1,
            });
          } else {
            let objects = rule_types[value]['objects'];
            let region = rule_types[value]['region'];

            if (objects.length > 1) {
              // 2 objects
              expressionFound.push({
                type: 'object',
                value: objects[0],
                edit: 1,
              });
              expressionFound.push({
                type: 'object',
                value: objects[1],
                edit: 1,
              });
            } else if (region.length == 0 && objects.length == 1) {
              // 1 object
              expressionFound.push({
                type: 'object',
                value: objects[0],
                edit: 1,
              });
            } else {
              // object region
              expressionFound.push({
                type: 'object',
                value: objects[0],
                edit: 1,
              });
              expressionFound.push({
                type: 'region',
                value: region,
                edit: 1,
              });
            }
          }
        });
        stepExpression.push(expressionFound);
      }
      let stepIndex = 0;
      this.taskProcesses.forEach((process) => {
        process['steps'].forEach((step, step_Index) => {
          step['substeps'].forEach((substep, substep_Index) => {
            this.verifiedSteps[step_Index][substep_Index] =
              verifiedStepDate[stepIndex];
            let is_bool = true;
            if (stepExpression.hasOwnProperty(stepIndex.toString())) {
              for (let i = stepExpression[stepIndex].length - 1; i >= 0; i--) {
                if (
                  ['AND', 'OR'].includes(stepExpression[stepIndex][i].value)
                ) {
                  is_bool = false;
                }
                if (!is_bool) {
                  stepExpression[stepIndex][i].edit = 0;
                }
              }
              this.expressions[process.name][step.name][substep] =
                stepExpression[stepIndex];
            }
            stepIndex += 1;
          });
        });
      });
    },
    generateStepsData() {
      let stepdata = {};
      let stepIndex = 0;
      this.taskProcesses.forEach((process) => {
        process['steps'].forEach((step, step_index) => {
          step['substeps'].forEach((substep, substep_index) => {
            console.log('verifiedSteps', this.verifiedSteps);
            const verified =
              this.verifiedSteps[step_index][substep_index] == null
                ? false
                : this.verifiedSteps[step_index][substep_index];
            const curr_expression =
              this.expressions[process.name][step.name][substep];
            var rule_types = {};
            var boolean_expression = '';
            // rule type and boolean expression
            var operator_split_expression = [];
            var temp_arr = [];

            for (let j = 0; j < curr_expression.length; j++) {
              if (j == curr_expression.length - 1) {
                temp_arr.push(curr_expression[j]);
                operator_split_expression.push(temp_arr);
                temp_arr = [];
              }
              if (['AND', 'OR', 'NOT'].includes(curr_expression[j].value)) {
                operator_split_expression.push(temp_arr);
                operator_split_expression.push([curr_expression[j]]);
                temp_arr = [];
              } else {
                temp_arr.push(curr_expression[j]);
              }
            }
            let rule_type_object_key = '';
            let rule_type_name = '';
            let time = '';
            let objects_present = [];
            for (let j = 0; j < operator_split_expression.length; j++) {
              if (operator_split_expression[j].length === 0) {
                continue;
              }
              if (Object.keys(operator_split_expression[j]).length === 0) {
                continue;
              }
              if (
                operator_split_expression[j][
                  operator_split_expression[j].length - 1
                ].type == 'time'
              ) {
                time =
                  'FOR_' +
                  operator_split_expression[j][
                    operator_split_expression[j].length - 1
                  ].value +
                  ' ';
              }

              if (
                operator_split_expression[j][0].type === 'operator' &&
                operator_split_expression[j][0].value != 'NOT'
              ) {
                boolean_expression =
                  boolean_expression +
                  rule_type_object_key +
                  ' ' +
                  time +
                  operator_split_expression[j][0].value +
                  ' ';
                time = '';
                continue;
              }
              if (
                operator_split_expression[j][0].type === 'operator' &&
                operator_split_expression[j][0].value == 'NOT'
              ) {
                boolean_expression =
                  boolean_expression +
                  operator_split_expression[j][0].value +
                  ' ';
                continue;
              }

              rule_type_name = '';
              rule_type_object_key = '';

              if (
                (operator_split_expression[j].length < 2 ||
                  operator_split_expression[j][1].type == 'time') &&
                operator_split_expression[j][0].type == 'object'
              ) {
                rule_type_name = 'object-detection';
                rule_type_object_key = (
                  operator_split_expression[j][0].value +
                  '_' +
                  rule_type_name
                ).replace(/ /g, '_');
              } else if (
                (operator_split_expression[j][0].type == 'object' &&
                  operator_split_expression[j][1].type == 'object') ||
                (operator_split_expression[j][1].type == 'object' &&
                  operator_split_expression[j][0].type == 'object')
              ) {
                if (
                  operator_split_expression[j][0].value == 'hands' ||
                  operator_split_expression[j][1].value == 'hands'
                )
                  rule_type_name = 'hand-object-interaction';
                else rule_type_name = 'object-object-interaction';

                rule_type_object_key = (
                  operator_split_expression[j][0].value +
                  '_' +
                  operator_split_expression[j][1].value +
                  '_' +
                  rule_type_name
                ).replace(/ /g, '_');
              } else if (
                (operator_split_expression[j][0].type == 'object' &&
                  operator_split_expression[j][1].type == 'region') ||
                (operator_split_expression[j][1].type == 'object' &&
                  operator_split_expression[j][0].type == 'region')
              ) {
                if (
                  operator_split_expression[j][0].value == 'hands' ||
                  operator_split_expression[j][1].value == 'hands'
                )
                  rule_type_name = 'hand-region-interaction';
                else rule_type_name = 'region-based-detection';

                if (operator_split_expression[j][0].type == 'object')
                  rule_type_object_key = (
                    operator_split_expression[j][0].value +
                    '_' +
                    operator_split_expression[j][1].value +
                    '_' +
                    rule_type_name
                  ).replace(/ /g, '_');
                else
                  rule_type_object_key = (
                    operator_split_expression[j][1].value +
                    '_' +
                    operator_split_expression[j][0].value +
                    '_' +
                    rule_type_name
                  ).replace(/ /g, '_');
              }

              if (j == operator_split_expression.length - 1) {
                boolean_expression = boolean_expression + rule_type_object_key;
                if (
                  operator_split_expression[j][
                    operator_split_expression[j].length - 1
                  ].type == 'time'
                ) {
                  boolean_expression =
                    boolean_expression +
                    ' FOR_' +
                    operator_split_expression[j][
                      operator_split_expression[j].length - 1
                    ].value +
                    ' ';
                }
              }

              // objects
              var objects = [];
              var region = '';
              operator_split_expression[j].forEach((obj) => {
                if (obj.type == 'object') objects.push(obj.value);
                if (obj.type == 'region') region = obj.value;
              });

              objects_present = [...objects_present, ...objects];

              rule_types[rule_type_object_key] = {
                objects: objects,
                rule_type_name: rule_type_name,
                region: region,
                condition: {
                  threshold_value: 0.1,
                },
              };
            }
            let prev_stepdata = this.stepsJsonData;

            if (
              prev_stepdata.hasOwnProperty(stepIndex.toString()) &&
              prev_stepdata[stepIndex.toString()].hasOwnProperty(
                'step_relations'
              ) &&
              prev_stepdata[stepIndex.toString()] != null &&
              prev_stepdata[stepIndex.toString()] != 'undefined'
            ) {
              stepdata[stepIndex.toString()] = {
                objects_present: [...new Set(objects_present)],
                detect_always: !verified,
                boolean_expr: boolean_expression.trim(),
                rule_types: rule_types,
                step_relations:
                  prev_stepdata[stepIndex.toString()]['step_relations'],
              };
            } else {
              stepdata[stepIndex.toString()] = {
                objects_present: [...new Set(objects_present)],
                detect_always: !verified,
                boolean_expr: boolean_expression.trim(),
                rule_types: rule_types,
                step_relations: {
                  'anchor-relation': {
                    key_steps: [],
                    before_anchor: [],
                    after_anchor: [],
                  },
                  'similar-relation': {
                    key_steps: [],
                    before_anchor: [],
                    after_anchor: [],
                  },
                },
              };
            }

            stepIndex += 1;
          });
        });
      });

      console.log(stepdata);
      return stepdata;
    },

    removeEmptyAnnotationObj(json) {
      const steps_data = this.annotationObjectJson['annotation'];
      if (json) {
        Object.keys(json).forEach((sIdx) => {
          const tempStepData = steps_data[sIdx];
          if (
            !tempStepData['non_static_object']?.length >= 1 &&
            !tempStepData['static_object'] &&
            !json[sIdx].hasOwnProperty('verify_step')
          ) {
            delete json[sIdx];
          }
        });
      }
      return json;
    },

    removeEmptySteps(json) {
      if (json) {
        Object.keys(json).forEach((sIdx) => {
          if (
            json.hasOwnProperty(sIdx.toString()) &&
            !Object.keys(json[sIdx.toString()]).length
          ) {
            delete json[sIdx];
          }
        });
      }
      return json;
    },
  },
};
</script>

<style scoped>
.process-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}
</style>
