<template>
  <div class="d-flex">
    <a-input
      class="mr-2"
      style="width: 30%;"
      v-model:value="tagId"
      placeholder="Enter Tag ID"
    />
    <a-button
      type="primary"
      :loading="IsAddTagButtonLoading"
      :disabled="isAddTagDisabled"
      @click="onAddTag"
      >Add Tag</a-button
    >
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import IoTService from 'src/services/iot';

export default {
  props: ['device'],
  emits: ['refresh'],
  inject: ['toast'],
  data() {
    return {
      tagId: '',
      IsAddTagButtonLoading: false,
      errorMessage: 'An unexpected error has occured. Please refresh the page.'
    };
  },
  computed: {
    ...mapGetters(['devices', 'selectedTask']),
    isAddTagDisabled() {
      return (
        this.tagId === '' || this.tagId === null || this.tagId === undefined
      );
    }
  },
  methods: {
    resetTagId() {
      this.tagId = '';
    },
    async onAddTag() {
      try {
        await this.addOPCTag();
      } catch (error) {
        this.IsAddTagButtonLoading = false;
        return this.toast.error(this.errorMessage);
      }
    },
    async addOPCTag() {
      this.IsAddTagButtonLoading = true;
      const payload = {
        tag_id: this.tagId,
        task: this.selectedTask,
        device: this.device.Device,
        serial_number: this.device.Serial_number,
        org: localStorage.getItem('organization')
      };
      await this.addTag(payload);
    },
    async addTag(payload) {
      const [error, data] = await IoTService.addTag(payload);
      if (error) {
        this.IsAddTagButtonLoading = false;
        const res = error?.response?.data[0] || '';
        return this.toast.error(`Failed to add OPC Tag. ${res}`, {
          timeout: 3000
        });
      }
      this.IsAddTagButtonLoading = false;
      this.resetTagId();
      this.$emit('refresh', data);
      this.toast.success('OPC Tag added successfully.', {
        timeout: 3000
      });
    }
  }
};
</script>

<style>
/* Add your styles here */
</style>
