<template>
  <!-- <div> -->
  <a-modal
    id="delete-task-modal"
    v-model:visible="showConfirmModal"
    centered
    title="Delete task"
    ok-text="Submit"
    :cancel-button-props="{
      id: 'delete-task-cancel-btn',
      style: { marginLeft: 'auto' },
    }"
    :ok-button-props="{ id: 'delete-task-ok-btn' }"
    @ok="handleCheckText"
    @cancel="
      () => {
        isError = false;
        taskDeleteMsg = '';
      }
    "
  >
    <template #closeIcon>
      <close-outlined id="delete-close-btn" />
    </template>
    <p>
      Are you sure you want to delete the task? This will cause any video data,
      labels, and analytics associated with the task to be lost.
    </p>
    <p>
      Please type '<i class="text-danger">delete task</i>' in the following box
      and press submit.
    </p>
    <div class="d-flex flex-column">
      <a-input
        id="delete-task-input"
        v-model:value="taskDeleteMsg"
        class="w-50"
      />
      <small v-if="isError" class="text-danger"> Please type correctly! </small>
    </div>
  </a-modal>

  <a-modal
    id="associate-regions-modal"
    v-model:visible="showAssociateRegionModal"
    centered
    width="80vw"
    destroy-on-close
    title="Associate Regions"
    :closable="false"
    :footer="null"
    @cancel="toggleAssociateRegionModal"
  >
    <AssociateRegion
      :show-associate-region-modal="showAssociateRegionModal"
      :selected-step-for-region="selectedStepForRegion"
      :selected-step-index="selectedStepIndex"
      @toggleAssociateRegionModal="toggleAssociateRegionModal"
    />
  </a-modal>

  <!-- Barcode Select Modal -->
  <!-- <a-modal
    id="add-barcode-modal"
    :visible="showBarcodeModal"
    :title="`Associate Barcode (${selectedStepForBarcode})`"
    centered
    :confirm-loading="isUpsertingAssociateBarcodes"
    width="45vw"
    ok-text="Save"
    @cancel="toggleBarcodeModal"
  >
    <template #closeIcon>
      <close-outlined id="add-barcode-close-btn" />
    </template>

    <a-select
      id="barcode-select-input"
      v-model:value="selectedBarcode"
      style="margin-bottom: 1em"
      class="w-100"
      show-search
      placeholder="Select Barcode"
      :options="barcodeOptions"
      :loading="isFetchingBarcodes"
      :filter-option="filterOption"
    />

    <QRCodeVue3
      :id="allBarcodes[selectedBarcode] + '-QR'"
      ref="QRCode"
      :value="allBarcodes[selectedBarcode]"
      :width="200"
      :height="200"
      :dots-options="{
        type: 'none',
        color: '#000000',
      }"
      :background-options="{ color: '#ffffff' }"
      :corners-square-options="{ type: 'square', color: '#000000' }"
      myclass="text-center"
    />

    <template #footer>
      <div class="d-flex align-items-center">
        <a-space>
          <a-button
            v-if="associatedBarcode"
            id="delete-barcode-btn"
            danger
            size="default"
            :loading="isRemovingAssociatedBarcode"
            @click="handleRemoveBarcode"
          >
            Remove
          </a-button>
          <a-button
            v-if="selectedBarcode"
            id="print-barcode-btn"
            @click="printBarcode"
          >
            Print Barcode
          </a-button>
        </a-space>
        <a-space class="ml-auto">
          <a-button id="add-barcode-cancel-btn" @click="toggleBarcodeModal">
            Cancel
          </a-button>
          <a-button
            id="add-barcode-ok-btn"
            type="primary"
            @click="handleSelectedBarcode"
          >
            Save
          </a-button>
        </a-space>
      </div>
    </template>
  </a-modal> -->

  <a-modal
    id="add-object-modal"
    v-model:visible="showObjectModal"
    title="Associate Objects/Regions"
    centered
    destroy-on-close
    @cancel="handleCloseModal"
  >
    <template #closeIcon>
      <close-outlined id="add-rule-obj-close-btn" />
    </template>
    <template #footer>
      <a-button
        id="cancel-add-object-btn"
        key="cancel"
        class="ml-auto"
        @click="handleCloseModal"
      >
        Cancel
      </a-button>
      <a-button
        id="save-add-object-btn"
        key="submit"
        form="objectForm"
        html-type="submit"
        type="primary"
        :loading="isSavingObjectDetectionRule"
      >
        Save
      </a-button>
    </template>
    <a-form
      id="objectForm"
      layout="vertical"
      :model="form"
      :rules="rules"
      @finish="handleSaveObjectDetectionRule"
    >
      <a-row :gutter="[8, 16]">
        <a-col span="24">
          <a-form-item name="non_static_object">
            <a-select
              id="add-non-static-obj-select"
              v-model:value="form.non_static_object"
              mode="multiple"
              style="width: 100%"
              placeholder="Select objects"
              :options="taskObjectOptions"
              class="w-100 mb-1"
              show-search
              :filter-option="filterOption"
              @select="handleAddObjects"
              @deselect="handleRemoveObjects"
            />
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </a-modal>

  <ObjectDetection />

  <a-card
    title="Assign Operation Attributes"
    style="flex-grow: 1; display: flex; flex-direction: column"
    :body-style="{
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    }"
  >
    <template #extra>
      <a-dropdown :trigger="['click']">
        <a-button type="text" @click.prevent>
          <template #icon>
            <more-outlined @click="checkTaskSelection" />
          </template>
        </a-button>
        <template #overlay>
          <a-menu v-if="selectedTask">
            <a-menu-item
              v-for="item in taskMenuItems"
              :key="item.label"
              @click="item.onClick"
            >
              <template #icon>
                <component :is="item.icon" />
              </template>
              {{ item.label }}
            </a-menu-item>
          </a-menu>
        </template>
      </a-dropdown>

      <!-- <a-popover v-if="!!negativeSteps.length && !!optionalSteps.length">
        <template #content>
          <p v-if="negativeSteps.length !== 0">
            <span class="color-box bg-danger" /> : Represents Negative Step(s).
          </p>
          <p v-if="optionalSteps.length !== 0">
            <span class="color-box bg-primary" /> : Represents Optional Step(s).
          </p>
        </template>
        <a-button
          v-if="selectedTask && stepsInfoShow"
          type="primary"
          size="small"
        >
          <template #icon>
            <InfoCircleOutlined />
          </template>
        </a-button>
      </a-popover> -->
    </template>

    <a-select
      id="define-process-task-select"
      ref="task_input"
      style="margin-bottom: 0.75em"
      class="w-100"
      :value="selectedTask"
      show-search
      :options="options"
      placeholder="Select Operation"
      :loading="isFetchingTasks"
      :filter-option="filterOption"
      @change="handleSelectTask"
    />

    <a-row v-if="taskName && selectedTask" :gutter="[8, 0]">
      <a-col span="12">
        <a-button
          id="english-translation-btn"
          block
          :type="selectedLang === 'English' ? 'primary' : 'default'"
          :disabled="isTaskBeingUpdated"
          @click="changeSelectedLanguage('English')"
        >
          English
        </a-button>
      </a-col>
      <a-col span="12">
        <a-button
          id="custom-translation-button"
          block
          :type="selectedLang === translationLanguage ? 'primary' : 'default'"
          :disabled="!translationLanguage || isTaskBeingUpdated"
          @click="changeSelectedLanguage(translationLanguage)"
        >
          {{ translationLanguage ? translationLanguage : 'Translation' }}
        </a-button>
      </a-col>
    </a-row>

    <a-card
      hoverable
      size="small"
      :loading="isFetchingTaskDetails"
      class="mt-2 flex-grow-1 d-flex flex-column"
      :body-style="{
        // height: containerHeight,
        flexGrow: '1',
        overflowY: 'auto',
        display: 'flex',
        padding: '0.5em',
      }"
    >
      <template #title>
        <div v-if="taskName && selectedTask" class="d-flex">
          <h6 class="my-auto">
            <a-tooltip :title="taskName">
              <strong>{{ getTaskName }}</strong>
            </a-tooltip>
          </h6>
          <a-space class="ml-auto task-operations">
            <a-button
              outlined
              :loading="isUpdatingTaskFields"
              @click="updateStarField"
            >
              <template #icon>
                <star-filled v-if="isTaskStarred" class="text-warning" />
                <star-outlined v-else />
              </template>
            </a-button>

            <!-- <a-button
              id="region-modal-btn"
              type="primary"
              @click="
                showModal({
                  modalType: types.SET_SHOW_REGION_DEFINE_MODAL,
                  value: true,
                })
              "
            >
              <template #icon>
                <custom-icon color="white" :icon="$customIcons.RegionIcon" />
              </template>
            </a-button> -->

            <a-button
              id="edit-task-btn"
              class="btn-warning"
              :loading="isFetchingTaskStatus"
              :disabled="isTaskBeingUpdated"
              @click="onClickEditTask"
            >
              <template #icon>
                <form-outlined class="text-light" />
              </template>
            </a-button>

            <a-button
              id="delete-task-btn"
              type="danger"
              :disabled="isTaskBeingUpdated"
              @click="showConfirmModal = true"
            >
              <template #icon>
                <delete-outlined />
              </template>
            </a-button>
          </a-space>
        </div>
      </template>

      <a-list
        v-if="!isTaskBeingUpdated"
        id="task-process-list"
        class="w-100 flex-grow-1"
        size="small"
        style="height: 1px"
        :data-source="taskProcesses"
      >
        <template #renderItem="{ item: process, index: processIndex }">
          <a-list-item>
            <a-card
              hoverable
              size="small"
              class="w-100"
              :body-style="{
                overflowY: 'auto',
                overflowX: 'hidden',
                // height: '45vh',
                padding: '0.5em',
              }"
            >
              <template #title>
                <h6 :id="'task-process-' + processIndex" class="m-0">
                  <span class="text-secondary">Process: </span>
                  <strong>{{ process.name }}</strong>
                </h6>
              </template>

              <a-list
                :id="'process-' + processIndex + '-steps'"
                :grid="{ gutter: 16, column: 1 }"
                size="small"
                :data-source="process.steps"
              >
                <template #renderItem="{ item: step, index: stepIndex }">
                  <a-list-item class="px-1 pb-0">
                    <a-card
                      :id="'process-' + processIndex + '-step-' + stepIndex"
                      hoverable
                      size="small"
                      class="w-100"
                      :body-style="{ padding: '0' }"
                    >
                      <template #title>
                        <p
                          :id="
                            'p' + processIndex + '-step' + stepIndex + '-name'
                          "
                          class="m-0"
                        >
                          <span class="text-secondary">Step: </span>
                          <strong>{{ step.name }}</strong>
                        </p>
                      </template>
                      <a-list
                        :id="
                          'p' + processIndex + '-step' + stepIndex + '-substeps'
                        "
                        size="small"
                        :data-source="step.substeps"
                      >
                        <template
                          #renderItem="{ item: substep, index: substepIndex }"
                        >
                          <a-list-item
                            :id="'step' + stepIndex + '-substep' + substepIndex"
                            class="ml-2 d-flex justify-content-between"
                          >
                            <div class="ml-n1">
                              <span
                                v-if="showBarCodeIcon(substep)"
                                :id="
                                  'qrcode-step' +
                                  stepIndex +
                                  '-substep' +
                                  substepIndex
                                "
                              >
                                <QrcodeOutlined />
                              </span>
                              <span class="mr-2"> substep: </span>
                              <span
                                :id="
                                  'step' +
                                  stepIndex +
                                  '-substep' +
                                  substepIndex +
                                  '-name'
                                "
                                class="text-right"
                                :style="{ fontSize: '13px' }"
                                :class="{
                                  'text-danger':
                                    stepToAttributesMapping[
                                      `${processIndex}-${stepIndex}-${substepIndex}`
                                    ]?.isNegative,
                                  'text-primary':
                                    stepToAttributesMapping[
                                      `${processIndex}-${stepIndex}-${substepIndex}`
                                    ]?.isOptional,
                                }"
                              >
                                {{ substep }}
                              </span>
                            </div>
                            <a-dropdown
                              :trigger="['click']"
                              placement="topRight"
                            >
                              <template #overlay>
                                <a-menu :id="substep + '-menuList'">
                                  <a-menu-item
                                    :id="substep + '-mark-optional-item'"
                                  >
                                    <a-checkbox
                                      :id="substep + '-mark-optional-checkbox'"
                                      class="ml-auto"
                                      :checked="
                                        stepToAttributesMapping[
                                          `${processIndex}-${stepIndex}-${substepIndex}`
                                        ]?.isOptional
                                      "
                                      :disabled="
                                        stepToAttributesMapping[
                                          `${processIndex}-${stepIndex}-${substepIndex}`
                                        ]?.isNegative
                                      "
                                      @change="
                                        handleChangeOptionalStepChange({
                                          processIndex,
                                          stepIndex,
                                          substepIndex,
                                        })
                                      "
                                    >
                                      Optional
                                    </a-checkbox>
                                  </a-menu-item>
                                  <a-menu-item :id="substep + '-mark-ve-item'">
                                    <a-checkbox
                                      :id="substep + '-mark-ve-checkbox'"
                                      class="ml-auto"
                                      :checked="
                                        stepToAttributesMapping[
                                          `${processIndex}-${stepIndex}-${substepIndex}`
                                        ]?.isNegative
                                      "
                                      :disabled="
                                        stepToAttributesMapping[
                                          `${processIndex}-${stepIndex}-${substepIndex}`
                                        ]?.isOptional
                                      "
                                      @change="
                                        handleChangeNegativeStepChange({
                                          processIndex,
                                          stepIndex,
                                          substepIndex,
                                        })
                                      "
                                    >
                                      Negative
                                    </a-checkbox>
                                  </a-menu-item>
                                </a-menu>
                              </template>
                              <a-button
                                :id="substep + '-menu-btn'"
                                class="ml-2"
                                :style="{
                                  width: '24px',
                                  height: '24px',
                                  fontSize: '12px',
                                }"
                              >
                                <template #icon>
                                  <MenuOutlined class="menu-icon" />
                                </template>
                              </a-button>
                            </a-dropdown>
                          </a-list-item>
                        </template>
                      </a-list>
                    </a-card>
                  </a-list-item>
                </template>
              </a-list>
            </a-card>
          </a-list-item>
        </template>
      </a-list>
      <a-result
        v-else
        class="m-auto"
        title="We are updating the selected task, this will take a while."
      >
        <template #icon>
          <SettingTwoTone spin />
        </template>
      </a-result>
    </a-card>

    <!-- Update Button -->
    <template #actions>
      <div>
        <a-button
          v-if="showUpdateButton"
          id="update-ve-steps-btn"
          class="ml-auto"
          type="primary"
          :disabled="isTaskBeingUpdated"
          @click="updateSteps"
        >
          <template #icon>
            <form-outlined />
          </template>
          Update Steps
        </a-button>

        <div
          v-else-if="taskName && selectedTask"
          class="d-flex flex-column align-items-center"
        >
          <a-space class="action-buttons">
            <a-button
              id="edit-task-params-button"
              type="primary"
              class="flex-grow-1"
              block
              @click="
                showModal({
                  modalType: types.SET_SHOW_TASK_PARAMS_MODAL,
                  value: true,
                })
              "
            >
              <form-outlined />
              Edit Operation Parameters
            </a-button>

            <!-- <a-button
              id="annot-obj-modal-btn"
              type="primary"
              @click="handleShowAnnotationObjectModal"
            >
              <form-outlined />
              Object Annotation
            </a-button> -->
            <a-button
              id="annot-obj-modal-btn"
              type="primary"
              class="flex-grow-1"
              block
              @click="
                showModal({
                  modalType: types.SET_SHOW_OD_SETUP_MODAL,
                  value: true,
                })
              "
            >
              <form-outlined />
              Setup Object Detection
            </a-button>
          </a-space>
          <a-space class="mt-1 action-buttons">
            <a-button
              id="add-translation-btn"
              type="primary"
              class="flex-grow-1"
              block
              :disabled="isTaskBeingUpdated"
              @click="upsertTranslation"
            >
              <template #icon>
                <plus-circle-outlined v-if="!translationDetails" />
                <form-outlined v-else />
              </template>
              Translation
            </a-button>
            <!-- <a-button
              id="add-similar-steps-btn"
              type="primary"
              :disabled="isTaskBeingUpdated"
              class="flex-grow-1"
              block
              @click="updateSimilarStepsModalState(true)"
            >
              <template #icon>
                <plus-circle-outlined
                  v-if="Object.keys(similarSteps).length === 0"
                />
                <form-outlined v-else />
              </template>
              Similar Steps
            </a-button> -->
            <a-button
              id="annot-obj-modal-btn"
              type="primary"
              class="flex-grow-1"
              block
              @click="
                showModal({
                  modalType: types.SET_SHOW_PROJECTOR_REGION_MODAL,
                  value: true,
                })
              "
            >
              <FundProjectionScreenOutlined />
              Setup IoT Plugins
            </a-button>
            <a-button
              id="add-step-time-btn"
              type="primary"
              :disabled="isTaskBeingUpdated"
              class="flex-grow-1"
              block
              @click="
                showModal({
                  modalType: types.SET_SHOW_STEP_TIME_MODAL,
                  value: true,
                })
              "
            >
              <template #icon>
                <plus-circle-outlined v-if="!isStepTimeEdit" />
                <form-outlined v-else />
              </template>
              Standard Time
            </a-button>
          </a-space>
        </div>
      </div>
    </template>

    <!-- </div> -->
  </a-card>
</template>

<script>
import {
  DeleteOutlined,
  FormOutlined,
  MenuOutlined,
  PlusCircleOutlined,
  CloseOutlined,
  QrcodeOutlined,
  SettingTwoTone,
  StarOutlined,
  StarFilled,
  InfoCircleOutlined,
  FundProjectionScreenOutlined,
  MoreOutlined,
  FileImageOutlined,
} from '@ant-design/icons-vue';
import spaceMixin from 'src/mixins/handleSpace';
import { mapActions, mapGetters } from 'vuex';
import types from 'src/store/mutation-types';
// import QRCodeVue3 from 'qrcode-vue3';
import printJS from 'print-js';
import AssociateRegion from '../RegionDefinition/AssociateRegion.vue';
import ObjectDetection from '../ObjectDetection/ObjectDetection.vue';
import longRunningTask from 'src/mixins/longRunningTask';
import { getSortedTask } from 'src/utils/task';
import { updatedAttributeSteps, deepClone } from '@/utils/task.js';
import axios from 'axios';
import uploadFile from '../../../../shared/Helpers/uploadFile';
import httpClient from '../../../../../service/httpClient';

export default {
  components: {
    PlusCircleOutlined,
    FormOutlined,
    DeleteOutlined,
    MenuOutlined,
    CloseOutlined,
    FundProjectionScreenOutlined,
    // QRCodeVue3,
    QrcodeOutlined,
    AssociateRegion,
    SettingTwoTone,
    StarOutlined,
    StarFilled,
    InfoCircleOutlined,
    ObjectDetection,
    MoreOutlined,
    FileImageOutlined,
  },
  mixins: [spaceMixin, longRunningTask],
  inject: ['toast'],
  setup() {
    const rules = {
      name: [{ required: false, message: 'rule is required' }],
      static_object: [
        { required: false, message: 'static object is required' },
      ],
      non_static_object: [
        { required: false, message: 'Non static object is required' },
      ],
    };
    return {
      rules,
      types,
      log: console.log,
    };
  },
  data() {
    return {
      showAssociateRegionModal: false,
      selectedBarcode: null,
      taskDeleteMsg: '',
      showConfirmModal: false,
      isError: false,
      showObjectModal: false,
      showBarcodeModal: false,
      selectedStepIndex: null,
      rulesIndexMapping: {},
      selectedRule: [],
      form: {
        name: '',
        static_object: null,
        non_static_object: [],
      },
      indexToBarcodeMapping: {},
      associatedBarcode: null,
      selectedStepForBarcode: '',
      selectedStepForRegion: '',
      showBarcode: false,
      selectedObjects: [],
      unselectedObjects: [],
      idToIsStaticMapping: {},
      oldObjects: [],
      taskQuery: {},
    };
  },

  computed: {
    ...mapGetters([
      'stepTime',
      'editModes',
      'associateBarcodes',
      'isFetchingAssociateBarcodes',
      'isUpsertingAssociateBarcodes',
      'allBarcodes',
      'isFetchingBarcodes',
      'isRemovingAssociatedBarcode',
      'allTasks',
      'isFetchingTasks',
      'isFetchingTaskDetails',
      'isFetchingTaskStatus',
      'selectedTask',
      'taskDetails',
      'isTaskStarred',
      'taskName',
      'taskProcesses',
      'negativeSteps',
      // 'stepToNegativeMapping',s
      'selectedLang',
      'translationLanguage',
      'translationDetails',
      'similarSteps',
      'taskObjects',
      'taskObjectRules',
      'stepsToIndexMapping',
      'isSavingObjectDetectionRule',
      'isUpdatingTaskFields',
      'stepToAttributesMapping',
      'optionalSteps',
      'taskAssociatedModelVersion',
      'stepsJsonData',
      'organization',
      'indexToStepsMapping',
      'selectedTaskModel',
      'subStepTimesJson',
    ]),

    getTaskName() {
      const charLimit = 50;
      let name = this.taskName?.slice(0, charLimit);
      if (this.taskName?.length > charLimit) name += '...';
      return name;
    },

    showUpdateButton() {
      return (
        updatedAttributeSteps(this.stepToAttributesMapping, 'isNegative') !==
          [...this.negativeSteps].sort().join(',') ||
        updatedAttributeSteps(this.stepToAttributesMapping, 'isOptional') !==
          [...this.optionalSteps].sort().join(',')
      );
    },

    containerHeight() {
      return (
        (this.taskName ? (this.showUpdateButton ? '49.5' : '45') : '59.7') +
        'vh'
      );
    },

    options() {
      return getSortedTask(this.allTasks);
    },

    barcodeOptions() {
      let usedBarcodes = this.associateBarcodes.map((el) => el.barcode_id);
      return Object.entries(this.allBarcodes).map(([key, value]) => ({
        value: Number(key),
        label: value,
      }));
      // .filter(
      //   (el) =>
      //     el.value === this.selectedBarcode ||
      //     !usedBarcodes.includes(el.value)
      // );
    },

    taskObjectOptions() {
      return this.taskObjects.map(({ name, id, is_static }) => ({
        value: id,
        label: `${name}  ${is_static ? '-  (region)' : ''}`,
      }));
    },

    isStepTimeEdit() {
      const { avgCycleTime, stepAvgTime } = this.stepTime;
      return Object.keys(stepAvgTime).length > 0 && !!avgCycleTime;
    },

    taskMenuItems() {
      return [
        {
          key: 'Edit Task Parameters',
          icon: 'FormOutlined',
          label: 'Edit Task Parameters',
          onClick: () =>
            this.showModal({
              modalType: types.SET_SHOW_TASK_PARAMS_MODAL,
              value: true,
            }),
        },
        {
          key: 'Barcode',
          icon:
            this.taskName && this.selectedTask
              ? 'QrcodeOutlined'
              : 'FormOutlined',
          label: 'Barcode',
          onClick: this.toggleBarcodeModal,
        },
        {
          key: 'Setup Object Detection',
          icon: 'FormOutlined',
          label: 'Setup Object Detection',
          onClick: () =>
            this.showModal({
              modalType: types.SET_SHOW_OD_SETUP_MODAL,
              value: true,
            }),
        },
        {
          key: 'Translation',
          icon: this.translationDetails ? 'FormOutlined' : 'PlusCircleOutlined',
          label: 'Translation',
          onClick: this.upsertTranslation,
        },
        {
          key: 'Model Version',
          icon: this.taskAssociatedModelVersion
            ? 'FormOutlined'
            : 'PlusCircleOutlined',
          label: 'Model Version',
          onClick: () =>
            this.showModal({
              modalType: types.SHOW_MODEL_VERSIONS_MODAL,
              value: true,
            }),
        },
        {
          key: 'Similar Steps',
          icon:
            Object.keys(this.similarSteps).length === 0
              ? 'PlusCircleOutlined'
              : 'FormOutlined',
          label: 'Similar Steps',
          onClick: () => this.updateSimilarStepsModalState(true),
        },
        {
          key: 'Projector',
          icon: 'FundProjectionScreenOutlined',
          label: 'Projector',
          onClick: () =>
            this.showModal({
              modalType: types.SET_SHOW_PROJECTOR_REGION_MODAL,
              value: true,
            }),
        },
        {
          key: 'Standard Time',
          icon: this.isStepTimeEdit ? 'FormOutlined' : 'PlusCircleOutlined',
          label: 'Standard Time',
          onClick: () =>
            this.showModal({
              modalType: types.SET_SHOW_STEP_TIME_MODAL,
              value: true,
            }),
        },
        {
          key: 'Upload Media',
          icon: 'FileImageOutlined',
          label: 'Upload Media',
          onClick: () =>
            this.showModal({
              modalType: types.SET_SHOW_UPLOAD_MEDIA_MODAL,
              value: true,
            }),
        },
      ];
    },
  },

  watch: {
    taskDetails(value) {
      if (!value) return;
      this.setTaskNameAndProcess(value);
    },

    async selectedTask(value) {
      this.clearLRTInterval();
      this.taskQuery = value ? { task: value } : null;
      if (!value) return;
      this.fetchTaskDetails(value);
      this.getTaskObjects(value);
      this.getTaskObjectRules(value);
      this.fetchModelVersionsList(value);
      this.get3dSupportInfo(value);
      this.getTaskStepsMediaObj(value);
      this.setSelectedLanguage('English');
      await this.fetchAssociateBarcodes(value);
      this.setTaskImageMarkingUrl('');
      this.setLongRunningTaskInterval();
      this.fetchVirtualTasks(value);
    },

    translationLang() {
      this.setSelectedLanguage('English');
    },

    taskProcesses(value) {
      if (!value || !value.length) return;
      this.setStepsToIndexMapping();
      this.setsubstepsToStepMapping();
      this.setStepToAttributesMapping();
      this.setIndexToStepsMapping();
    },

    taskObjectRules(rules) {
      let temp_step_to_object_list = rules.map(function (el) {
        return {
          step: el.step,
          object: el.non_static_object || el.static_object,
        };
      });
      var stepObjects = [];
      temp_step_to_object_list.forEach((stepObj) => {
        var stepIndex = stepObjects?.findIndex((s) => s.step === stepObj.step);
        if (stepObjects.length && stepIndex >= 0) {
          stepObjects[stepIndex] = {
            ...stepObjects[stepIndex],
            objects: [...stepObjects[stepIndex].objects, stepObj.object],
          };
        } else {
          stepObjects.push({
            step: stepObj.step,
            objects: [stepObj.object],
          });
        }
      });
      let reduce_objects = stepObjects.reduce((res, el) => {
        res[el.step] = el;
        return res;
      }, {});

      this.rulesIndexMapping = reduce_objects;
    },

    associateBarcodes(barcodes) {
      this.indexToBarcodeMapping = barcodes.reduce((res, el) => {
        res[el.step_index] = el;
        return res;
      }, {});
    },

    indexToBarcodeMapping(value) {
      console.log('indexToBarcodeMapping', value);
    },

    taskObjects(objects) {
      this.idToIsStaticMapping = objects.reduce((res, el) => {
        res[el.id] = el;
        return res;
      }, {});
    },

    taskQuery(query) {
      this.updateQueryParams(query);
    },
  },

  mounted() {
    this.clearTaskDetails();
    this.populateTaskParams();
    this.fetchAllBarcodes();
    if (this.selectedTask) {
      this.getTaskObjects(this.selectedTask);
      this.setLongRunningTaskInterval();
    }
    this.setListener();
  },

  beforeUnmount() {
    this.removeListener();
  },

  methods: {
    ...mapActions([
      'updateAssociateBarcodes',
      'createAssociateBarcodes',
      'fetchAssociateBarcodes',
      'fetchAllBarcodes',
      'removeAssociatedBarcode',
      'setCurrentEditMode',
      'setSelectedTask',
      'setTaskId',
      'setTaskNameAndProcess',
      'fetchTaskDetails',
      'clearTaskDetails',
      'deleteTask',
      'setSelectedLanguage',
      'handleChangeNegativeStepChange',
      'updateNegativeSteps',
      'editTask',
      'upsertTranslation',
      'changeSelectedLanguage',
      'updateSimilarStepsModalState',
      'getTaskObjects',
      'getTaskObjectRules',
      'createTaskObjectRule',
      'deleteTaskObjectRules',
      'setStepsToIndexMapping',
      'setsubstepsToStepMapping',
      'updateTaskObjectDetectionRule',
      'showModal',
      'setTaskOrganization',
      'setTaskImageMarkingUrl',
      'updateTaskFields',
      'setIsTaskStarred',
      'setTasks',
      'setStepToAttributesMapping',
      'updateSteps',
      'handleChangeOptionalStepChange',
      'createStepsJsonData',
      'setSubStepTimesJsonData',
      'setIndexToStepsMapping',
      'fetchModelVersionsList',
      'setStepsAssociationIds',
      'handleShowAnnotationObjectModal',
      'fetchVirtualTasks',
      'get3dSupportInfo',
      'getTaskStepsMediaObj',
      'patchTaskLastUpdate',
    ]),

    checkTaskSelection() {
      if (!this.selectedTask)
        this.toast.info('Please select a Task first!', { timeout: 2000 });
    },

    stepsInfoShow() {
      return this.optionalSteps.length > 0 || this.negativeSteps.length > 0;
    },

    handleSelectTask(val) {
      this.setSelectedTask(val);
      this.$refs.task_input.blur();
    },

    preventUndo(e) {
      if (e.ctrlKey && e.key.toLowerCase() === 'z') {
        e.preventDefault();
      }
    },

    handleCheckText() {
      if (this.taskDeleteMsg?.trim() !== 'delete task') {
        this.isError = true;
        return;
      }
      this.showConfirmModal = false;
      this.deleteTask(this.selectedTask);
      this.isError = false;
      this.taskDeleteMsg = '';
    },

    handleCloseModal() {
      this.showObjectModal = false;
      this.form.name = '';
      this.form.static_object = this.form.non_static_object = [];
      this.selectedObjects = [];
      this.unselectedObjects = [];
      this.oldObjects = [];
    },

    handleOpenObjectModal(stepName) {
      this.selectedStepIndex = this.stepsToIndexMapping[stepName];
      this.selectedRule = this.rulesIndexMapping[this.selectedStepIndex];

      if (this.selectedRule) {
        // this.form.name = this.selectedRule.name;
        // this.form.static_object = this.selectedRule.static_object;
        this.form.non_static_object = this.selectedRule.objects.filter(
          (obj) => obj !== null
        );
        this.oldObjects = this.selectedRule.objects;
      }
      this.showObjectModal = true;
    },

    async handleSaveObjectDetectionRule() {
      let objectsToAdd = this.getObjectToAdd();
      let objectsToRemove = this.getObjectToRemove();

      const data = objectsToAdd.map((objId) => {
        const object = this.idToIsStaticMapping[objId];
        const key = object.is_static ? 'static_object' : 'non_static_object';
        return {
          step: this.selectedStepIndex,
          task: this.selectedTask,
          name: object.name,
          [key]: objId,
        };
      });

      let resp = '';
      if (objectsToAdd.length) {
        const response = await this.createTaskObjectRule(data);
        if (response === 'error') {
          this.toast.error(
            'error occured in creating an object detection rule'
          );
        }
        if (response !== 'error') resp = response;
      }

      const objectToDelete = objectsToRemove.toString();
      if (objectsToRemove.length) {
        let params = {
          ids: objectToDelete,
          task: this.selectedTask,
          step: this.selectedStepIndex,
        };
        const response = await this.deleteTaskObjectRules(params);
        if (response === 'error') {
          this.toast.error(
            'error occured in deleting an object detection rule'
          );
        }
        if (response !== 'error') {
          resp = response;
        }
      }
      if (resp !== 'error') {
        this.buildStepsDataJson(objectsToAdd, objectsToRemove);
        this.toast.success('Object Detection Rules Updated Successfully!');
        this.handleCloseModal();
      }
    },

    getObjectToAdd() {
      if (!this.selectedRule) return this.selectedObjects;
      return this.selectedObjects.filter(
        (objId) => !this.oldObjects.includes(objId)
      );
      // if (this.selectedRule.objects.length > this.selectedObjects.length) {
      //   if (
      //     !this.selectedObjects.every((el) =>
      //       this.selectedRule.objects.includes(el)
      //     )
      //   ) {
      //     let difference = this.selectedObjects.filter(
      //       (x) => !this.selectedRule.objects.includes(x)
      //     );
      //     return difference;
      //   }
      // } else {
      //   if (
      //     !this.selectedRule.objects.every((el) =>
      //       this.selectedObjects.includes(el)
      //     )
      //   ) {
      //     let difference = this.selectedObjects.filter(
      //       (x) => !this.selectedRule.objects.includes(x)
      //     );
      //     return difference;
      //   }
      // }
    },

    getObjectToRemove() {
      if (!this.selectedRule) return [];
      return this.unselectedObjects.filter((objId) =>
        this.oldObjects.includes(objId)
      );
      // if (this.selectedRule.objects.length > this.unselectedObjects.length) {
      //   if (
      //     this.unselectedObjects.every((el) =>
      //       this.selectedRule.objects.includes(el)
      //     )
      //   ) {
      //     let intersection = this.unselectedObjects.filter((x) =>
      //       this.selectedRule.objects.includes(x)
      //     );
      //     return intersection;
      //   }
      // } else {
      //   if (
      //     this.unselectedObjects.every((el) =>
      //       this.selectedRule.objects.includes(el)
      //     )
      //   ) {
      //     let intersection = this.unselectedObjects.filter((x) =>
      //       this.selectedRule.objects.includes(x)
      //     );
      //     return intersection;
      //   }
      // }
    },

    toggleBarcodeModal() {
      this.showBarcodeModal = !this.showBarcodeModal;
    },

    getSelectedBarcode(stepName) {
      this.selectedStepForBarcode = stepName;
      this.selectedStepIndex = this.stepsToIndexMapping[stepName];
      let barcode = this.indexToBarcodeMapping[this.selectedStepIndex];
      this.selectedBarcode = barcode?.barcode_id || null;
      this.associatedBarcode = barcode?.barcode_id ? barcode : null;
      this.toggleBarcodeModal();
    },

    toggleAssociateRegionModal() {
      this.showAssociateRegionModal = !this.showAssociateRegionModal;
    },

    handleAssociateRegionModal(stepName) {
      this.selectedStepForRegion = stepName;
      this.selectedStepIndex = this.stepsToIndexMapping[stepName];
      this.toggleAssociateRegionModal();
    },

    handleCloseRegionDefineModal() {
      this.toggleAssociateRegionModal();
    },

    setIndexToBarcodeMapping() {
      let barcodes = this.associateBarcodes.reduce((res, el) => {
        res[el.step_index] = el;
        return res;
      }, {});
      return barcodes;
    },

    showBarCodeIcon(stepName) {
      // if (!Object.keys(this.indexToBarcodeMapping).length) {
      //   this.indexToBarcodeMapping = this.setIndexToBarcodeMapping();
      // }
      let selectedStepIndex = this.stepsToIndexMapping[stepName];
      let barcode = this.indexToBarcodeMapping[selectedStepIndex];
      if (barcode != undefined) {
        return true;
      }
    },

    handleSelectedBarcode() {
      let payload = {
        task: this.selectedTask,
        barcode: this.selectedBarcode,
        step_index: this.selectedStepIndex,
      };
      let prev = this.indexToBarcodeMapping[this.selectedStepIndex];
      if (prev)
        this.updateAssociateBarcodes({ id: prev.id, payload }).then((res) => {
          if (res) this.toggleBarcodeModal();
        });
      else
        this.createAssociateBarcodes(payload).then((res) => {
          if (res) this.toggleBarcodeModal();
        });
    },

    handleRemoveBarcode() {
      this.removeAssociatedBarcode(this.associatedBarcode.id).then((res) => {
        if (res) this.toggleBarcodeModal();
      });
    },

    handleAddObjects(objID) {
      this.selectedObjects.push(objID);
      const index = this.unselectedObjects.indexOf(objID);
      if (index > -1) {
        this.unselectedObjects.splice(index, 1);
      }
    },

    handleRemoveObjects(objID) {
      if (objID) {
        this.unselectedObjects.push(objID);
        const index = this.selectedObjects.indexOf(objID);
        if (index > -1) {
          this.selectedObjects.splice(index, 1);
        }
      }
    },

    printBarcode() {
      printJS({
        printable: this.$refs.QRCode.imageUrl,
        type: 'image',
        header: `<div style="font-family: 'Montserrat', sans-serif;">
          <h2>Task Name:
            <span style="font-weight: 500">${this.taskName}</span>
          </h2>
          <h3>Step Name:
            <span style="font-weight: 500">${this.selectedStepForBarcode}</span>
          </h3>
        </div>`,
        imageStyle: 'width:60%;',
      });
    },

    onClickEditTask() {
      this.editTask();
      const organization = localStorage.getItem('organization');
      this.setTaskOrganization(organization);
    },

    startInterval() {
      this.isTaskBeingUpdated = true;
      this.showTaskUpdateNotification();
      setTimeout(this.setLongRunningTaskInterval, 4000);
    },

    setListener() {
      this.emitter.on('startLRTInterval', this.startInterval);
      document.addEventListener('keydown', this.preventUndo);
    },

    removeListener() {
      this.emitter.off('startLRTInterval', this.startInterval);
      document.removeEventListener('keydown', this.preventUndo);
    },
    async updateStarField() {
      try {
        await this.updateTaskFields({ starred: !this.isTaskStarred });
      } catch (error) {
        console.log('error occured while updated star property.', error);
      }
      const tempTasks = [...this.allTasks];
      const taskIndex = tempTasks.findIndex((t) => t.id === this.selectedTask);
      tempTasks[taskIndex]['starred'] = !this.isTaskStarred;
      this.setTasks(tempTasks);
      this.setIsTaskStarred(!this.isTaskStarred);
    },

    buildStepsDataJson(addedObjects, removedObjects) {
      let data = []; // contains current objects & regions
      // only remove
      if (!addedObjects.length && removedObjects.length) {
        data = this.oldObjects.filter((obj) => !removedObjects.includes(obj));
      }
      // add and remove
      if (addedObjects.length && removedObjects.length) {
        data = this.oldObjects.filter((obj) => !removedObjects.includes(obj));
        data = [...data, ...addedObjects];
      }
      //only add
      if (addedObjects.length && !removedObjects.length) {
        data = [...addedObjects, ...this.oldObjects];
      }
      data = data.map((objId) => {
        const object = this.idToIsStaticMapping[objId];
        const key = object.is_static ? 'static_object' : 'non_static_object';
        return {
          name: object.name,
          [key]: objId,
        };
      });
      let temp = {};

      if (this.stepsJsonData != null && Object.keys(this.stepsJsonData).length)
        temp = JSON.parse(JSON.stringify(this.stepsJsonData));

      const step = this.selectedStepIndex.toString();
      const region = data.find((o) => o.hasOwnProperty('static_object'));
      const objects = data.filter((o) => o.hasOwnProperty('non_static_object'));
      if (region && objects.length) {
        temp[step] = {
          objects_present: objects.map((obj) => obj.name),
          detect_always: true,
          rule_type: {
            'hand-region-interaction': {
              objects: objects.map((obj) => obj.name),
              region: region?.name,
              condition: {
                threshold_value: 0.1,
              },
            },
          },
        };
      } else if (temp[step]) delete temp[step];

      this.createStepsJsonData(temp);
    },

    populateTaskParams() {
      const query = this.$route.query;
      if (query.task) {
        const taskId = Number(query.task);
        this.handleSelectTask(taskId);
      }
    },

    updateQueryParams(query) {
      this.$router.replace({
        name: this.$route.name,
        query: query,
      });
    },
  },
};
</script>
<style>
.menu-icon {
  font-size: 12px;
}

.action-buttons {
  display: flex !important;
  width: 100% !important;
  padding: 0 5em;
}

.action-buttons > .ant-space-item {
  flex-grow: 1;
}

#edit-task-btn.ant-btn {
  background: #fbe460;
  border-color: #fbe460;
}

#edit-task-btn.ant-btn:hover {
  background: #ffc107;
  border-color: #ffc107;
}
</style>
